<template>
  <v-row>
    <!-- Advanced usage  -->
    <v-col md="12">
      <v-progress-linear
        v-if="b_Loading"
        class="my-1"
        indeterminate
        color="primary"
      />
      <base-card>
        <v-card-title>Liste der Klassen</v-card-title>
        <v-card-text>
          <v-select
            v-model="i_CurrentSeminarCenterID"
            :items="a_SeminarCenter"
            item-text="s_Name"
            item-value="i_SeminarCenterID"
            filled
            label="Standort"
            dense
            outlined
            color="success"
            @change="setSeminarCenterID"
            class="mr-2"
            v-if="b_IsTeacherAdmin"
          />
          <v-list
            two-line
            subheader
          >
            <v-list-item
              v-for="o_Class in a_Classes"
              :key="o_Class.id"
              :to="{
                name: 'students-list-pma',
                params: { id: o_Class.i_ClassID}
              }"
            >
              <v-list-item-avatar >
                <v-icon
                  :class="[o_Class.iconClass]"
                  v-text="o_Class.icon"
                  :color="o_Class.b_ClassTeacher ? '' : 'blue lighten-4'"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="o_Class.s_Name" />
                <v-list-item-subtitle v-text="o_Class.s_Description" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { get } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Klassen',
    },
    data () {
      return {
        courses: [],
        b_IsTeacherAdmin: false,
        a_SeminarCenter: [],
        a_Studies: [],
        i_CurrentSeminarCenterID: null,
        b_Loading: true,
        i_TeacherAddressRoleID: null,
      }
    },
    watch: {
      i_CurrentSeminarCenterID: function (val) {
        this.getTeacherStudies()
      },
      i_PlanningPeriodID () {
        this.b_Loading = true
        //this.getCourses()
        if (!this.isSiteManager) this.getTeacherStudies()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getSeminarCenter', 'isSiteManager', 'getImpersonation']),
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      a_Classes () {
        const La_Classes = []
        if (this.b_IsTeacherAdmin) {
          if (this.i_CurrentSeminarCenterID === null) return []
          const La_SeminarCenterStudy = this.a_Studies.map(
            o_Study => {
              return {
                i_StudiesID: o_Study.i_StudiesID,
                s_Name: o_Study.s_Name,
                a_Class: o_Study.a_Class.filter(o_Class => {
                  return o_Class.i_SeminarCenterID === this.i_CurrentSeminarCenterID
                })
              }              
            }
          ) 
          La_SeminarCenterStudy.forEach(o_Study => {
            o_Study.a_Class.forEach(o_Class => {
            La_Classes.push({
              id: o_Study.i_StudiesID + '_' + o_Class.i_ClassID,
              s_Name: o_Class.s_Name,
              s_Description: this.isSiteManager && !this.getImpersonation.b_Status ? '' : o_Class.s_Description,
              i_ClassID: o_Class.i_ClassID,
              iconClass: o_Class.b_ClassTeacher ? 'success lighten-1 white--text' : 'blue lighten-1 white--text',
              icon: o_Class.b_ClassTeacher ? 'mdi-folder-account-outline' : 'mdi-folder',
              b_ClassTeacher: o_Class.b_ClassTeacher,
            })
          })
          })
          return La_Classes.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
        }
        this.a_Studies.forEach(o_Study => {
          o_Study.a_Class.forEach(o_Class => {
            La_Classes.push({
              id: o_Study.i_StudiesID + '_' + o_Class.i_ClassID,
              s_Name: o_Class.s_Name,
              s_Description: this.isSiteManager && !this.getImpersonation.b_Status ? '' : o_Class.s_Description,
              i_ClassID: o_Class.i_ClassID,
              iconClass: o_Class.b_ClassTeacher ? 'success lighten-1 white--text' : 'blue lighten-1 white--text',
              icon: o_Class.b_ClassTeacher ? 'mdi-folder-account-outline' : 'mdi-folder',
              b_ClassTeacher: o_Class.b_ClassTeacher,
            })
          })
        })
        return La_Classes.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
      },
    },
    methods: {
      getCourses () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TeacherAddressRoleID}/period/${this.getPlanningPeriodID}/courses`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.courses = response
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTeacherStudies () {
        if (this.isSiteManager && !this.getImpersonation.b_Status) {
          get(`${process.env.VUE_APP_SMT_API_URL}/data/period/studies`, {
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 0,
            i_SeminarCenterID: this.i_CurrentSeminarCenterID
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response
              this.b_Loading = false
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }
        else {
          get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/studies`, {
            i_AddressRoleID: this.i_TeacherAddressRoleID,
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 0,
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response
              this.b_Loading = false
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }  
      },
      setSeminarCenterID (val) {
        this.i_CurrentSeminarCenterID = val
      },
    },
    beforeMount () {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TeacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TeacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      //this.getCourses()
      this.a_SeminarCenter = this.getSeminarCenter
      this.b_IsTeacherAdmin = this.a_SeminarCenter.length > 0 && !this.getImpersonation.b_Status
      if (this.a_SeminarCenter.length > 0) {
        this.i_CurrentSeminarCenterID = this.a_SeminarCenter[0].i_SeminarCenterID
      }
      if (!this.isSiteManager) this.getTeacherStudies()
    },
  }
</script>
